import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { verificaMesAnoRedesIguais, salvarResultadoStatus } from '@/utils/_validacoesGrafico';
import { tokenExpirado } from '@/utils/tokenExpirado';
//
//
export function buscarEngajamentoDoMes(data, revenda) {
  if (!verificaMesAnoRedesIguais(data, store.state.engajamento.engajamentoDoMes)) return false;
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarEngajamentoDoMes, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('engajamento/SET_ENGAJAMENTO_DO_MES', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscarEngajadosPorRevenda(data, revenda) {
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    if (!verificaMesAnoRedesIguais(data, store.state.engajamento.engajadosPorRevenda)) return resolve();
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarEngajadosPorRevenda, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('engajamento/SET_ENGAJADOS_POR_REVENDA', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscarDesempenhoTreinamento(data, revenda) {
  buscarDesempenhoTreinamentoMes(data, revenda);
  buscarDesempenhoTreinamentoRevenda(data, revenda);
}

function buscarDesempenhoTreinamentoMes(data, revenda) {
  if (!verificaMesAnoRedesIguais(data, store.state.engajamento.desempenhoTreinamentoMes)) return false;
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarDesempenhoTreinamentoMes, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('engajamento/SET_DESEMPENHO_TREINAMENTO_MES', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

function buscarDesempenhoTreinamentoRevenda(data, revenda) {
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    if (!verificaMesAnoRedesIguais(data, store.state.engajamento.desempenhoTreinamentoRevenda)) return resolve();
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarDesempenhoTreinamentoRevenda, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('engajamento/SET_DESEMPENHO_TREINAMENTO_REVENDA', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
