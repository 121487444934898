import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { verificaMesAnoRedesIguais, salvarResultadoStatus } from '@/utils/_validacoesGrafico';
import { tokenExpirado } from '@/utils/tokenExpirado';
//
//
export function buscarProdutividade(data, revenda, status = 1) {
  buscarProdutividadeMes(data, revenda, status);
  buscarProdutividadeRevenda(data, revenda, status);
}

function buscarProdutividadeMes(data, revenda) {
  if (!verificaMesAnoRedesIguais(data, store.state.produtividade.produtividadeMes)) return false;
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarProdutividadeMes, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('produtividade/SET_PERCENTUAL_MENSAL_VENDAS_MES', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

function buscarProdutividadeRevenda(data, revenda) {
  if (!verificaMesAnoRedesIguais(data, store.state.produtividade.produtividadeRevenda)) return false;
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarProdutividadeRevenda, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('produtividade/SET_PERCENTUAL_MENSAL_VENDAS_REVENDA', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
