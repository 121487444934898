import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { verificaMesAnoRedesIguais, verificaMesAnoRedesIguaisComFiltro, salvarResultadoStatus, salvarResultadoBusca } from '@/utils/_validacoesGrafico';
import { tokenExpirado } from '@/utils/tokenExpirado';
//
//
export function buscarTotalParticipantes(data, revenda) {
  if (!verificaMesAnoRedesIguais(data, store.state.usuarios.totalParticipantes)) return false;
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarTotalParticipantes, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('usuarios/SET_TOTAL_PARTICIPANTES', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscaNovosVendedores(data, revenda, status = 1) {
  return new Promise((resolve) => {
    const arrStatus = ['', 'ativo', 'inativo', 'preCadastro', 'somenteCatalogo', 'convideAmigo'];
    let objBusca = data;
    objBusca.Status = status;
    if (!verificaMesAnoRedesIguaisComFiltro(objBusca, store.state.usuarios.novosVendedoresBusca, store.state.usuarios.novosVendedoresData[arrStatus[status]], 'usuarios/SET_NOVOS_VENDEDORES_APAGAR')) {
      return resolve();
    }
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarNovosVendedores, objBusca, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoBusca(objBusca, revenda);
        store.commit('usuarios/SET_NOVOS_VENDEDORES_BUSCA', resultado);
        store.commit('usuarios/SET_NOVOS_VENDEDORES_DATA', { obj: arrStatus[status], data: resp.data.data });
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve();
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscaRevendasStatus(data, revenda, status = 1) {
  return new Promise((resolve) => {
    const arrStatus = ['', 'ativo', 'inativo', 'preCadastro', 'somenteCatalogo', 'convideAmigo'];
    let objBusca = data;
    objBusca.Status = status;
    if (!verificaMesAnoRedesIguaisComFiltro(objBusca, store.state.usuarios.revendasStatusBusca, store.state.usuarios.revendasStatusData[arrStatus[status]], 'usuarios/SET_REVENDAS_STATUS_APAGAR')) {
      return resolve();
    }
    const token = localStorage.getItem('token');
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarRevendasStatus, objBusca, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoBusca(objBusca, revenda);
        store.commit('usuarios/SET_REVENDAS_STATUS_BUSCA', resultado);
        store.commit('usuarios/SET_REVENDAS_STATUS_DATA', { obj: arrStatus[status], data: resp.data.data });
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve();
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
