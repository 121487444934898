import store from '@/store';
import { buscarTotalParticipantes, buscaNovosVendedores, buscaRevendasStatus } from '@/services/usuarios';
import { buscarTotalVendas, buscarVendasPorCategoria, buscarRankingDeRevendas, buscarVendasPorStatus, buscarRevendasPorCategoria } from '@/services/vendas';
import { buscarPontuacaoMes, buscarPontuacaoRevenda } from '@/services/pontuacao';
import { buscarProdutividade } from '@/services/produtividade';
import { buscarAtendimento, buscarAtendimentoOcorrencias } from '@/services/atendimento';
import { buscarEngajamentoDoMes, buscarEngajadosPorRevenda, buscarDesempenhoTreinamento } from '@/services/engajamento';
//
export function carregaPagina(funcao, status) {
  if (!funcao || !store.state.dadosPesquisa.mesPesquisa || !store.state.dadosPesquisa.revendasSelecionadas || store.state.dadosPesquisa.revendasSelecionadas.length == 0) return false;
  let data = {
    MesVigente: store.state.dadosPesquisa.mesPesquisa,
    AnoVigente: store.state.dadosPesquisa.anoPesquisa,
    Revendas: [],
    Status: 0,
  };
  let revenda = false;
  if (store.state.dadosPesquisa.revendasSelecionadas && store.state.dadosPesquisa.revendasSelecionadas != 'null' && store.state.dadosPesquisa.revendasSelecionadas.length > 0) {
    data.Revendas = store.state.dadosPesquisa.revendasSelecionadas;
    revenda = true;
  }
  return new Promise((resolve) => {
    switch (funcao) {
      // Usuarios
      case 'buscarTotalParticipantes':
        return buscarTotalParticipantes(data, revenda);
      case 'buscaNovosVendedores':
        return buscaNovosVendedores(data, revenda, status).then(() => {
          resolve();
        });
      case 'buscaRevendasStatus':
        return buscaRevendasStatus(data, revenda, status).then(() => {
          resolve();
        });
      // Vendas
      case 'buscarTotalVendas':
        return buscarTotalVendas(data, revenda, status);
      case 'buscarVendasPorCategoria':
        return buscarVendasPorCategoria(data, revenda, status);
      case 'buscarRankingDeRevendas':
        return buscarRankingDeRevendas(data, revenda, status).then(() => {
          resolve();
        });
      case 'buscarVendasPorStatus':
        return buscarVendasPorStatus(data, revenda, status).then(() => {
          resolve();
        });
      case 'buscarRevendasPorCategoria':
        return buscarRevendasPorCategoria(data, revenda, status).then(() => {
          resolve();
        });
      // Pontuacao
      case 'buscarPontuacaoMes':
        return buscarPontuacaoMes(data, revenda, status).then(() => {
          resolve();
        });
      case 'buscarPontuacaoRevenda':
        return buscarPontuacaoRevenda(data, revenda, status).then(() => {
          resolve();
        });
      // Produtividade
      case 'buscarProdutividade':
        return buscarProdutividade(data, revenda, status);
      // Engajamento
      case 'buscarEngajamentoDoMes':
        return buscarEngajamentoDoMes(data, revenda, status);
      case 'buscarEngajadosPorRevenda':
        return buscarEngajadosPorRevenda(data, revenda, status);
      case 'buscarDesempenhoTreinamento':
        return buscarDesempenhoTreinamento(data, revenda, status);
      // Atendimento
      case 'buscarAtendimento':
        return buscarAtendimento(data, revenda, status);
      case 'buscarAtendimentoOcorrencias':
        return buscarAtendimentoOcorrencias(data, revenda, status);
    }
  });
}

export function verificaMesAnoRedesIguais(data, vuex) {
  if (!store.state.dadosPesquisa.primeiraBusca) {
    store.commit('dadosPesquisa/SET_PRIMEIRA_BUSCA', true);
    return true;
  }
  if (!vuex || vuex.length == 0 || data.MesVigente != vuex.mesPesquisa || data.AnoVigente != vuex.anoPesquisa || data.Revendas.join('-') != vuex.revendasSelecionadas.join('-')) {
    return true;
  }
  return false;
}

export function verificaMesAnoRedesIguaisComFiltro(data, vuex, statusCarregado, commit) {
  if (!store.state.dadosPesquisa.revendasSelecionadas || store.state.dadosPesquisa.revendasSelecionadas.length == 0) {
    if (!store.state.dadosPesquisa.primeiraBusca) {
      store.commit('dadosPesquisa/SET_PRIMEIRA_BUSCA', true);
    }
    return false;
  }
  if (!store.state.dadosPesquisa.primeiraBusca) {
    store.commit('dadosPesquisa/SET_PRIMEIRA_BUSCA', true);
    return true;
  }
  if (!vuex || vuex.length == 0 || data.MesVigente != vuex.mesPesquisa || data.AnoVigente != vuex.anoPesquisa || (vuex.revendasSelecionadas && data.Revendas.join('-') != vuex.revendasSelecionadas.join('-'))) {
    store.commit(commit);
    return true;
  }
  if (!statusCarregado) {
    return true;
  }
  return false;
}

export function salvarResultadoStatus(data, resp, revenda) {
  let resultado = {
    mesPesquisa: data.MesVigente,
    anoPesquisa: data.AnoVigente,
    data: resp.data.data,
    revendasSelecionadas: [],
    Status: 0,
  };
  if (revenda) {
    resultado.revendasSelecionadas = store.state.dadosPesquisa.revendasSelecionadas;
  }
  return resultado;
}

export function salvarResultadoBusca(objBusca, revenda) {
  let resultado = {
    mesPesquisa: objBusca.MesVigente,
    anoPesquisa: objBusca.AnoVigente,
    revendasSelecionadas: [],
  };
  if (revenda) {
    resultado.revendasSelecionadas = store.state.dadosPesquisa.revendasSelecionadas;
  }
  return resultado;
}

export function verificaGraficoInformacaoZerada(arr) {
  const verificaArrayZerado = [];
  if (!arr) {
    return true;
  }
  arr.forEach((item) => {
    verificaArrayZerado.push(
      item.some((valor) => {
        return typeof valor == 'number' && valor > 0;
      })
    );
  });
  const verificador = verificaArrayZerado.some((item) => {
    return item == true;
  });
  return verificador ? true : false;
}
