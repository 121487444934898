import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { verificaMesAnoRedesIguais, verificaMesAnoRedesIguaisComFiltro, salvarResultadoStatus, salvarResultadoBusca } from '@/utils/_validacoesGrafico';
import { tokenExpirado } from '@/utils/tokenExpirado';
//
//
export function buscarTotalVendas(data, revenda) {
  if (!verificaMesAnoRedesIguais(data, store.state.vendas.totalVendas)) return false;
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarTotalVendas, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('vendas/SET_TOTAL_VENDAS', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscarVendasPorCategoria(data, revenda) {
  if (!verificaMesAnoRedesIguais(data, store.state.vendas.vendasPorCategoria)) return false;
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarVendasPorCategoria, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('vendas/SET_VENDAS_POR_CATEGORIA', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscarRankingDeRevendas(data, revenda, status = 1) {
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    const arrStatus = ['', 'total', 'participantes', 'supertops', 'brastemp', 'consul'];
    let objBusca = data;
    objBusca.Status = status;
    if (!verificaMesAnoRedesIguaisComFiltro(objBusca, store.state.vendas.rankingRevendasBusca, store.state.vendas.rankingRevendasData[arrStatus[status]], 'vendas/SET_RANKING_REVENDAS_APAGAR')) {
      return resolve();
    }
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarRankingDeRevendas, objBusca, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoBusca(objBusca, revenda);
        store.commit('vendas/SET_RANKING_REVENDAS_BUSCA', resultado);
        store.commit('vendas/SET_RANKING_REVENDAS_DATA', { obj: arrStatus[status], data: resp.data.data });
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve();
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscarVendasPorStatus(data, revenda, status = 1) {
  buscarVendasPorStatusNumeros(data, revenda, status);
  return new Promise((resolve) => {
    buscarVendasPorStatusMes(data, revenda, status).then(() => resolve());
  });
}

function buscarVendasPorStatusNumeros(data, revenda) {
  if (!verificaMesAnoRedesIguais(data, store.state.vendas.vendasPorStatusNumeros)) return false;
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarVendasPorStatusNumeros, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoStatus(data, resp, revenda);
        store.commit('vendas/SET_VENDAS_POR_STATUS_NUMEROS', resultado);
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve(resp);
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

function buscarVendasPorStatusMes(data, revenda, status) {
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    const arrStatus = ['', 'ativo', 'inativo', 'preCadastro', 'somenteCatalogo', 'convideAmigo'];
    let objBusca = data;
    objBusca.Status = status;
    if (!verificaMesAnoRedesIguaisComFiltro(objBusca, store.state.vendas.vendasPorStatusGraficoBusca, store.state.vendas.vendasPorStatusGraficoData[arrStatus[status]], 'vendas/SET_VENDAS_POR_STATUS_GRAFICO_APAGAR')) {
      return resolve();
    }
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarVendasPorStatusMensal, objBusca, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoBusca(objBusca, revenda);
        store.commit('vendas/SET_VENDAS_POR_STATUS_GRAFICO_BUSCA', resultado);
        store.commit('vendas/SET_VENDAS_POR_STATUS_GRAFICO_DATA', { obj: arrStatus[status], data: resp.data.data });
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve();
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscarRevendasPorCategoria(data, revenda, status = 1) {
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    const arrStatus = ['', 'ativo', 'inativo', 'preCadastro', 'somenteCatalogo', 'convideAmigo'];
    let objBusca = data;
    objBusca.Status = status;
    if (!verificaMesAnoRedesIguaisComFiltro(objBusca, store.state.vendas.revendasPorCategoriaBusca, store.state.vendas.revendasPorCategoriaData[arrStatus[status]], 'vendas/SET_REVENDAS_POR_CATEGORIA_APAGAR')) {
      return resolve();
    }
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarRevendasPorCategoria, objBusca, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoBusca(objBusca, revenda);
        store.commit('vendas/SET_REVENDAS_POR_CATEGORIA_BUSCA', resultado);
        store.commit('vendas/SET_REVENDAS_POR_CATEGORIA_DATA', { obj: arrStatus[status], data: resp.data.data });
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve();
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
