<template>
  <div class="trocar-revenda-select">
    <div class="trocar-revenda-select-box" v-if="revendas">
      <form>
        <multiselect v-model="regionaisSelect" label="nome" track-by="id" :searchable="false" :options="regionais" :multiple="false" placeholder="Selecione a Regional" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" :close-on-select="true" @close="fecharSelectRegionais"> </multiselect>
        <multiselect v-if="regionalSelecionada" v-model="revendasSelect" label="nome" track-by="id" :max="10" :searchable="false" :options="revendasFiltradas" :multiple="true" placeholder="Selecione as Revendas" select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" :close-on-select="false" :limit="5" :limit-text="limitText" @close="fecharSelectRevendas">
          <span slot="noResult">
            Nenhuma revenda encontrada.
          </span>
          <span slot="maxElements">
            Máximo de 10 revendas.
          </span>
        </multiselect>
      </form>
    </div>
    <button class="trocar-revenda-select-btn" :disabled="!revendasSelect || revendasSelect.length == 0 || ((!revendasSelect || revendasSelect.length == 0) && (!revendasSelecionadas || revendasSelecionadas.length == 0)) || camposIguais == true" @click="selecionarRevendas">Ok</button>
  </div>
</template>

<script>
import { carregaPagina } from '@/utils/_validacoesGrafico';
import Multiselect from 'vue-multiselect';
export default {
  name: 'SelectRevenda',
  components: {
    Multiselect,
  },
  data() {
    return {
      regionaisSelect: null,
      revendasSelect: null,
      camposIguais: false,
    };
  },
  computed: {
    regionais() {
      return this.$store.state.dadosPesquisa.regionais;
    },
    revendas() {
      return this.$store.state.dadosPesquisa.revendas;
    },
    regionalSelecionada() {
      return this.$store.state.dadosPesquisa.regionalSelecionada;
    },
    revendasSelecionadas() {
      return this.$store.state.dadosPesquisa.revendasSelecionadas;
    },
    revendasOptions() {
      return this.$store.state.dadosPesquisa.revendasOptions;
    },
    revendasFiltradas() {
      return !this.regionaisSelect ? this.revendas : this.revendas.filter((item) => item.regional == this.regionaisSelect.id);
    },
    //
  },
  methods: {
    carregaPagina,
    fecharSelectRegionais() {
      this.$store.commit('dadosPesquisa/SET_REGIONAL_SELECIONADA', this.regionaisSelect);
    },
    fecharSelectRevendas() {
      if (!this.revendasSelect || this.revendasSelect.length < 1) {
        this.revendasSelect = null;
      }
    },
    limitText(count) {
      return count > 1 ? `e mais ${count} revendas` : 'e mais 1 revenda';
    },
    selecionarRevendas() {
      let idsRevendas = null;
      if (this.revendasSelect) {
        idsRevendas = this.revendasSelect.map((item) => {
          return item.id;
        });
      }
      this.$store.commit('dadosPesquisa/SET_REVENDAS_SELECIONADAS', idsRevendas);
      this.$store.commit('dadosPesquisa/SET_REVENDAS_OPTIONS', this.revendasSelect);
      this.verificaCamposIguais();
      const funcoes = this.$router.history.current.meta.funcao;
      funcoes.split(',').forEach(item => this.carregaPagina(item));
    },
    verificaCamposIguais() {
      if (!this.revendasSelect) return false;
      if (!this.revendasOptions || this.revendasOptions.length == 0) {
        return this.camposIguais == false;
      }
      let idsRevendasSelect = this.revendasSelect.map((item) => {
        return item.id;
      });
      let idsRevendasVuex = this.revendasOptions.map((item) => {
        return item.id;
      });
      if (String(idsRevendasSelect.join('-')) == String(idsRevendasVuex.join('-'))) {
        this.camposIguais = true;
      } else {
        this.camposIguais = false;
      }
    },
  },
  watch: {
    regionalSelecionada: {
      deep: true,
      handler() {
        this.regionaisSelect = this.regionalSelecionada;
        if (!this.regionalSelecionada) {
          this.revendasSelect = null;
        } else {
          this.revendasSelect = null;
          this.revendasSelect = this.revendasFiltradas;
        }
      },
    },
    revendasSelect: {
      deep: true,
      handler() {
        this.verificaCamposIguais();
      },
    },
    revendasOptions: {
      deep: true,
      handler() {
        this.revendasSelect = this.revendasOptions;
      },
    },
  },
  mounted() {
    if (this.regionalSelecionada) {
      this.regionaisSelect = this.regionalSelecionada;
    }
    this.revendasOptions && this.revendasOptions.length > 0 ? (this.revendasSelect = this.revendasOptions) : false;
    this.verificaCamposIguais();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.trocar-revenda-select {
  max-width: 400px;
  margin: 0 auto;
  form > div:first-child {
    margin-bottom: 5px;
  }
  .trocar-revenda-select-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    height: 42px;
    margin: 20px auto 0 auto;
    cursor: pointer;
    border: none;
    border-radius: 25px;
    outline: none;
    background: $amarelo1;
    transition: 0.3s;
    font-size: 13px;
    font-weight: 900;
    &:hover {
      background: darken($amarelo1, 5%);
    }
    &:disabled {
      background: $cinza1;
      cursor: default;
    }
  }
}
</style>
