import axios from 'axios';
import store from '@/store';
import { _rotasAPI } from '@/services/_caminhosApi';
import { verificaMesAnoRedesIguaisComFiltro, salvarResultadoBusca } from '@/utils/_validacoesGrafico';
import { tokenExpirado } from '@/utils/tokenExpirado';
//
export function buscarPontuacaoMes(data, revenda, status = 0) {
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    const arrStatus = ['totalDePontos', 'vendas', 'treinamentos', 'campanhas'];
    let objBusca = data;
    objBusca.Status = status;
    if (!verificaMesAnoRedesIguaisComFiltro(objBusca, store.state.pontuacao.pontuacaoMesBusca, store.state.pontuacao.pontuacaoMesData[arrStatus[status]], 'pontuacao/SET_PONTUACAO_MES_APAGAR')) {
      return resolve();
    }
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarPontuacaoMes, objBusca, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoBusca(objBusca, revenda);
        store.commit('pontuacao/SET_PONTUACAO_MES_BUSCA', resultado);
        store.commit('pontuacao/SET_PONTUACAO_MES_DATA', { obj: arrStatus[status], data: resp.data.data });
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve();
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}

export function buscarPontuacaoRevenda(data, revenda, status = 0) {
  const token = localStorage.getItem('token');
  return new Promise((resolve) => {
    const arrStatus = ['totalDePontos', 'vendas', 'treinamentos', 'campanhas'];
    let objBusca = data;
    objBusca.Status = status;
    if (!verificaMesAnoRedesIguaisComFiltro(objBusca, store.state.pontuacao.pontuacaoRevendaBusca, store.state.pontuacao.pontuacaoRevendaData[arrStatus[status]], 'pontuacao/SET_PONTUACAO_REVENDAS_APAGAR')) {
      return resolve();
    }
    store.commit('SET_ADICIONAR_LOADING');
    axios
      .post(_rotasAPI().buscarPontuacaoRevenda, objBusca, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        const resultado = salvarResultadoBusca(objBusca, revenda);
        store.commit('pontuacao/SET_PONTUACAO_REVENDAS_BUSCA', resultado);
        store.commit('pontuacao/SET_PONTUACAO_REVENDAS_DATA', { obj: arrStatus[status], data: resp.data.data });
        if (store.state.modal != '') store.commit('SET_MODAL', '');
        resolve();
      })
      .catch((erro) => tokenExpirado(erro, true))
      .finally(() => store.commit('SET_REMOVER_LOADING'));
  });
}
